<template>
  <!-- 登录 -->
  <div class="login">
    <div class="contentAll" v-if="isShowlogin">
      <!-- 顶部功能条 -->
      <div class="trial">
        <div class="onTrial">
          <!--国外域名后缀是cloudcc.com的隐藏，cloudcc.cn的显示-->
          <div class="onTrial2" v-show="REGION === 'cn' && !isShowBtn">
            <img src="./img/ball.svg" />
            <el-select
              :value="'中国站'"
              placeholder=""
              :style="{ width: REGION === 'cn' ? '70px' : '115px' }"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <div @click="toLogin(item.url)">
                  <div>{{ item.label }}</div>
                </div>
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="content">
        <!-- 左侧轮播图 -->
        <div class="content_left">
          <!--
            自动轮播停止 :autoplay="false"
            左右切换按钮 arrow="never"
          -->
          <el-carousel
            indicator-position="outside"
            :interval="7000"
            arrow="never"
            @change="change1"
          >
            <el-carousel-item v-for="item in rotationList" :key="item.index">
              <div class="rotationImg">
                <img :src="item.imgSrc" />
              </div>
              <div class="introduce">
                <span>{{ $t(item.product) }}</span>
                <p align="center">{{ $t(item.title) }}</p>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="detail" @click="viewDetails">
            <!-- 查看详情 -->
            {{ $t("label.account.view.details") }}
          </div>
        </div>
        <!-- 右侧登录相关 -->
        <div class="content_right">
          <!-- 登录From -->
          <div class="loginForm">
            <div
              class="topDiv"
              :style="{
                margin: REGION === 'com' ? '20px 0 40px 0' : '20px 0 30px 0',
              }"
            >
              <!-- v-if="REGION === 'cn'" -->
              <img class="logol" :src="LOGIN_LOGO" alt="" />
              <!-- <img v-else class="logol" src="./img/logoCom.svg" alt="" /> -->
            </div>
            <!-- 登陆 -->
            <!-- <span class="mb24">{{ $t("label.account.signin") }}</span> -->
            <div class="formDiv">
              <el-form
                ref="form"
                :model="form"
                :rules="rules"
                class="formLogin"
              >
                <!-- 账号密码登录 -->
                <div v-if="loginL == 'account'">
                  <el-form-item prop="userName" class="formItem mb24">
                    <!--账号-->
                    <el-input
                      v-model.trim="form.userName"
                      :placeholder="$t('label.account.accountNum')"
                      @keyup.enter.native="verifyAccount"
                    >
                      <img
                        slot="prefix"
                        class="el-input__icon1"
                        src="./img/user3x.png"
                        alt=""
                      />
                    </el-input>
                    <!--删除按钮-->
                    <img
                      v-show="form.userName.length > 0"
                      class="delete1Btn"
                      src="./img/delete-icon.svg"
                      alt=""
                      @click="delete1Btn('userName')"
                    />
                  </el-form-item>
                  <el-form-item prop="password" class="formItem mb20">
                    <!-- 密码-->
                    <el-input
                      v-model.trim="form.password"
                      :type="passwordType"
                      :placeholder="$t('label.account.password')"
                      @keyup.enter.native="verifyAccount"
                    >
                      <img
                        slot="prefix"
                        class="el-input__icon1"
                        src="./img/pass3x.png"
                        alt=""
                    /></el-input>
                    <img
                      v-show="form.password.length > 0"
                      class="delete1Btn"
                      src="./img/delete-icon.svg"
                      alt=""
                      @click="delete1Btn('password')"
                    />
                  </el-form-item>
                </div>
                <!-- 手机号密码登录 -->
                <div v-if="loginL == 'phone' && false">
                  <el-form-item prop="" class="formItem mb24">
                    <el-col :span="7">
                      <!--区号选择-->
                      <el-form-item prop="Area">
                        <el-select
                          v-model="form.Area"
                          class="select112"
                          placeholder=""
                          :popper-append-to-body="false"
                          @change="changeArea"
                        >
                          <el-option
                            v-for="item in prefixOptions"
                            :key="$t(item.value)"
                            :label="$t(item.label)"
                            :value="$t(item.label)"
                          >
                            <div class="option1">
                              <span>{{ $t(item.country) }}</span>
                              <span class="span50">{{ $t(item.label) }}</span>
                            </div>
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="17">
                      <!--手机号-->
                      <el-form-item prop="phone">
                        <el-input
                          v-model.trim="form.phone"
                          :placeholder="$t('label.account.mobile.num')"
                          autocomplete="new-password"
                          @keyup.enter.native="verifyAccount"
                        >
                          <img
                            slot="prefix"
                            class="el-input__icon1"
                            src="./img/user3x.png"
                            alt=""
                          />
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <img
                      v-show="form.phone.length > 0"
                      class="delete1Btn"
                      src="./img/delete-icon.svg"
                      alt=""
                      @click="delete1Btn('phone')"
                    />
                  </el-form-item>
                  <!--密码-->
                  <el-form-item prop="password" class="formItem mb20">
                    <el-input
                      v-model.trim="form.password"
                      :type="passwordType"
                      :placeholder="$t('label.account.password')"
                      autocomplete="new-password"
                      @keyup.enter.native="verifyAccount"
                    >
                      <img
                        slot="prefix"
                        class="el-input__icon1"
                        src="./img/pass3x.png"
                        alt=""
                    /></el-input>
                    <img
                      v-show="form.password.length > 0"
                      class="delete1Btn"
                      src="./img/delete-icon.svg"
                      alt=""
                      @click="delete1Btn('password')"
                    />
                  </el-form-item>
                </div>
                <!-- 手机验证码登录 -->
                <div v-if="loginL == 'Verification' && false">
                  <el-form-item prop="" class="formItem mb24">
                    <el-col :span="7">
                      <!--区号-->
                      <el-form-item prop="Area">
                        <el-select
                          v-model="form.Area"
                          class="select112"
                          placeholder=""
                          :popper-append-to-body="false"
                          @change="changeArea"
                        >
                          <el-option
                            v-for="item in prefixOptions"
                            :key="$t(item.value)"
                            :label="$t(item.label)"
                            :value="$t(item.label)"
                          >
                            <div class="option1">
                              <span>{{ $t(item.country) }}</span>
                              <span class="span50">{{ $t(item.label) }}</span>
                            </div>
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <!--手机号-->
                    <el-col :span="17">
                      <el-form-item prop="phone">
                        <el-input
                          v-model.trim="form.phone"
                          :placeholder="$t('label.account.mobile.num')"
                          autocomplete="new-password"
                          @keyup.enter.native="verifyAccount"
                        >
                          <img
                            slot="prefix"
                            class="el-input__icon1"
                            src="./img/user3x.png"
                            alt=""
                          />
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <img
                      v-show="form.phone.length > 0"
                      class="delete1Btn"
                      src="./img/delete-icon.svg"
                      alt=""
                      @click="delete1Btn('phone')"
                    />
                  </el-form-item>
                  <!--验证码-->
                  <el-form-item prop="code" class="formItem mb20">
                    <el-input
                      v-model.trim="form.code"
                      :type="passwordType"
                      :placeholder="$t('label.account.label.captcha')"
                      autocomplete="new-password"
                      @keyup.enter.native="verifyAccount"
                    >
                      <img
                        slot="prefix"
                        class="el-input__icon1"
                        src="./img/pass3x.png"
                        alt=""
                    /></el-input>
                    <img
                      v-show="form.code.length > 0"
                      class="delete1Btn"
                      src="./img/delete-icon.svg"
                      alt=""
                      @click="delete1Btn('code')"
                    />
                    <div
                      v-show="form.code.length < 1"
                      class="countDown"
                      :class="{ Available: isAvailable }"
                    >
                      <span v-if="isShowObtain" @click="obtain">
                        <!-- 获取 -->
                        {{ $t("label.account.obtain") }}
                      </span>
                      <span v-else>{{ count }}s</span>
                    </div>
                    <div v-if="isShowInvalid" class="invalid">
                      <!-- 15分钟后 -->
                      <!-- 验证码失效 -->
                      {{ $t("label.account.codeFailure") }}
                    </div>
                  </el-form-item>
                </div>
                <!-- 输入框以下内容 -->
                <el-form-item>
                  <div class="forgetPass">
                    <!-- 记住密码 -->
                    <el-checkbox
                      v-if="loginL != 'Verification' && loginL != 'phone'"
                      v-model="checked"
                      class="checked"
                    >
                      {{ $t("label.account.rememberPassword") }}
                    </el-checkbox>
                    <!-- 忘记密码 -->
                    <span></span>
                    <span @click="dialogFormVisible = true">
                      {{ $t("label.account.forget.password") }}
                    </span>
                  </div>

                  <!-- Login -->
                  <div style="display: flex; justify-content: center">
                    <div
                      class="button-loading"
                      :class="{ animate: loginFlag }"
                      @click="verifyAccount"
                    >
                      {{ $t("label.account.signin") }}
                    </div>
                  </div>
                  <div
                    class="checkedOrTo"
                    :style="{
                      justifyContent: REGION === 'com' ? 'flex-end' : '',
                    }"
                  >
                    <!--  -->
                    <div class="loginList" v-show="REGION === 'cn' && false">
                      <div v-if="this.$i18n.locale != 'zh'" class="Loginby">
                        {{ $t("label.account.Loginby") }}
                      </div>
                      <div class="loginList1">
                        <span
                          v-show="loginL != 'account'"
                          @click="changeLoginL('account')"
                        >
                          <!-- 账号密码登录 -->
                          {{ $t("label.account.AccountLogin") }}
                        </span>
                        <i v-show="loginL != 'account'" />
                        <span
                          v-show="loginL != 'phone'"
                          @click="changeLoginL('phone')"
                        >
                          <!-- 手机号密码登录 -->
                          {{ $t("label.account.MobileLogin") }}
                        </span>
                        <i
                          v-show="
                            loginL != 'Verification' && loginL == 'account'
                          "
                        />
                        <span
                          v-show="loginL != 'Verification'"
                          @click="changeLoginL('Verification')"
                        >
                          <!-- 动态验证码登录 -->
                          {{ $t("label.account.VerificationLogin") }}
                        </span>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
              <!-- 其他登陆方式 -->
              <!-- <span>企业微信</span> -->
              <div v-if="REGION === 'cn'" class="otherLogin">
                <el-divider content-position="center">
                  {{ $t("label.account.other.Ways") }}
                </el-divider>
                <div class="otherLoginList">
                  <img
                    class="icon"
                    src="./img/企业微信.svg"
                    alt=""
                    @click="qiyeLogin"
                    v-if="!isShowBtn"
                  />
                  <!-- Daimler OIDC(New) -->
                  <el-button v-if="isShowBtn" type="" @click="JumpURL"
                    >{{ $t("c2395") }} Mercedes-Benz OIDC</el-button
                  >
                </div>
              </div>
              <div v-else class="otherLogin" />
              <div class="agreeDiv">
                <p class="agree">
                  <!-- By Signing Up, You Accept Our -->
                  {{ $t("label.account.Login.consent") }}
                  <!-- 登录即视为同意 -->
                  <span
                    @click="
                      REGION === 'cn'
                        ? Jump(
                            'https://informations.cloudcc.com/policy/terms-zh.html'
                          )
                        : Jump('https://www.cloudcc.com/en/company/terms')
                    "
                  >
                    <!-- Terms Of Service -->
                    <span v-if="this.$i18n.locale == 'zh'">
                      《{{ $t("label.account.Terms.service") }}》
                    </span>
                    <span v-else>
                      {{ $t("label.account.Terms.service") }}
                    </span>
                  </span>
                  <!-- 及 -->
                  {{ $t("label.account.and") }}
                  <span
                    @click="
                      REGION === 'cn'
                        ? Jump(
                            'https://informations.cloudcc.com/policy/PrivacyPolicy-zh.html'
                          )
                        : Jump('https://www.cloudcc.com/en/company/privacy')
                    "
                  >
                    <!-- Privacy Policy -->
                    <!-- 《{{ $t("label.account.Privacy.policy") }}》 -->
                    <span v-if="this.$i18n.locale == 'zh'">
                      《{{ $t("label.account.Privacy.policy") }}》
                    </span>
                    <span v-else>
                      {{ $t("label.account.Privacy.policy") }}
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="contentBottom">
        <div class="footer">
          <div>
            <el-popover placement="top" trigger="hover">
              <div class="codeD">
                <img style="width: 130px" src="./img/codeD.svg" alt="" />
                <span>
                  <!-- 扫码码下载IOS客户端 -->
                  {{ $t("label.account.IOS") }}
                </span>
              </div>
              <div slot="reference" style="padding: 0; margin-right: 25px">
                <img src="./img/iOS-icon.svg" alt="" />
              </div>
            </el-popover>
            <el-popover placement="top" trigger="hover">
              <div class="codeD">
                <img style="width: 130px" src="./img/codeD.svg" alt="" />
                <span>
                  <!-- 扫码码下载安卓客户端 -->
                  {{ $t("label.account.Android") }}
                </span>
              </div>
              <div slot="reference" style="padding: 0; margin-right: 25px">
                <img src="./img/安卓-icon.svg" alt="" />
              </div>
            </el-popover>
          </div>
        </div>
        <!--             免费试用 -->
        <div class="onTrial1" v-if="!isShowBtn">
          <span>
            <!-- 还没有账号？ -->
            {{ $t("c2224") }}
          </span>

          <div class="onTrial1_button" @click="toRegister">
            {{ $t("label.account.trial") }}
          </div>
        </div>
      </div>
    </div>
    <!--绑定MFA-->
    <enableMFA
      ref="enableMFA"
      :key="key + 'e'"
      @changeKey="changeKey"
    ></enableMFA>
    <!--验证MFA-->
    <validationMFA
      ref="validationMFA"
      :key="key + 'v'"
      @changeKey="changeKey"
    ></validationMFA>
    <!-- 忘记密码弹框 -->
    <Reset
      v-if="dialogFormVisible"
      :dialog-form-visible="dialogFormVisible"
      @recovery="recovery"
      @resetLogin="resetLogin"
    />
  </div>
</template>

<script>
import Reset from "./component/reset";
import * as loginApi from "./api";
import * as obtain from "@/utils/obtain";
import enableMFA from "./component/enableMFA";
import validationMFA from "./component/validationMFA";
import encry from "@/config/encryption";
export default {
  components: {
    Reset,
    enableMFA,
    validationMFA,
  },
  data() {
    var validateUserArea = (rule, value, callback) => {
      if (value === "") {
        // 请填写区号
        callback(new Error(this.$t("label.account.fillAreaCode")));
      } else {
        callback();
      }
    };
    var validateUserName = (rule, value, callback) => {
      if (value === "") {
        // "用户名不能为空"
        callback(new Error(this.$t("label.account.username.isnull")));
      } else if (this.returnInfo) {
        callback(new Error(this.returnInfo));
      } else {
        callback();
      }
    };
    var validatePassword = (rule, value, callback) => {
      // "密码不能为空"
      if (value === "") {
        callback(new Error(this.$t("label.account.password.isnull")));
      } else if (this.returnInfo) {
        callback(new Error(this.returnInfo));
      } else {
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      let phoneRe;
      if (this.form.Area == "+86") {
        // 中国+86
        phoneRe = /^(\+?0?86-?)?1[345789]\d{9}$/;
      } else if (this.form.Area == "+852") {
        // 中国香港+852
        phoneRe = /^(\+?852-?)?[569]\d{3}-?\d{4}$/;
      } else if (this.form.Area == "+886") {
        // 中国台湾+886
        phoneRe = /^(\+?886-?|0)?9\d{8}$/;
      } else if (this.form.Area == "+853") {
        // 中国澳门+853    // /^(853)?6\d{7}$/
        phoneRe = /^(\+?853-?)?6\d{7}$/;
      } else if (this.form.Area == "+1") {
        // 美国+1
        phoneRe = /^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/;
      } else if (this.form.Area == "+81") {
        // 日本+81
        phoneRe = /^(\+?81|0)\d{1,4}[ -]?\d{1,4}[ -]?\d{4}$/;
      } else if (this.form.Area == "+49") {
        // 德国+349
        phoneRe = /^(\+?49[ .-])?0?[19][1567]\d{1,2}(?:[ /-]*\d){7,8}(?!\d)/;
      } else if (this.form.Area == "+7") {
        // 俄罗斯+7
        phoneRe = /^(\+?7|8)?9\d{9}$/;
      } else if (this.form.Area == "+44") {
        // 英国+44
        phoneRe = /^(\+?44|0)7\d{9}$/;
      } else if (this.form.Area == "+33") {
        // 法国+33
        phoneRe = /^(\+?33|0)[67]\d{8}$/;
      } else if (this.form.Area == "+39") {
        // 意大利+39
        phoneRe = /^(\+?39)?\s?3\d{2} ?\d{6,7}$/;
      } else if (this.form.Area == "+34") {
        // 西班牙+34
        phoneRe = /^(\+?34)?(6\d{1}|7[1234])\d{7}$/;
      } else if (this.form.Area == "+48") {
        // 波兰+48
        phoneRe = /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/;
      } else if (this.form.Area == "+55") {
        // 巴西+55
        phoneRe = /^(\+?55|0)-?[1-9]{2}-?[2-9]{1}\d{3,4}-?\d{4}$/;
      }
      if (value === "") {
        // callback(new Error("手机号不能为空"));
        callback(new Error(this.$t("label.account.Mobile.not.empty")));
        if (this.loginL == "Verification") {
          this.isAvailable = true;
        }
      } else if (!phoneRe.test(value)) {
        // callback(new Error("手机格式有误,请重新录入"));
        callback(new Error(this.$t("label.account.Incorrect.format")));
        if (this.loginL == "Verification") {
          this.isAvailable = true;
        }
      } else if (this.returnInfo) {
        callback(new Error(this.returnInfo));
      } else {
        // 验证码获取按钮显示可用
        if (this.loginL == "Verification") {
          this.isAvailable = false;
        }
        callback();
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("label.account.code.not.empty")));
      } else if (this.returnInfo) {
        callback(new Error(this.returnInfo));
      } else {
        callback();
      }
    };

    return {
      LOGIN_LOGO: window.Glod.LOGIN_LOGO || require("./img/logo2x.png"),
      isShowlogin: true,
      key: 1,
      loginStatus: "mfavalidate", // 调登录接口后的状态标识
      // 禁止多次点击请求接口
      loginFlag: false,
      // 浏览器名称及版本
      explorer: "",
      // 操作系统名称
      operatingSystem: "",
      //用于区分中国站还是国际站
      REGION: "",
      // 密码最小长度
      minPswLength: "",
      // 密码复杂性要求：0-无要求 1-必须混合使用字母和数字 2-必须包含大小写字母及数字 3-必须包含大小写字母数字及符号 //正则
      pswComplexReq: /./,
      // 密码复杂性要求：0-无要求 1-必须混合使用字母和数字 2-必须包含大小写字母及数字 3-必须包含大小写字母数字及符号
      pswComplexReqRules: "",
      // 控制dialog显隐
      dialogFormVisible: false,
      // 海外登录图标
      convenientList: [
        {
          name: "google",
          imgSrc: require("./img/image16.png"),
        },
        {
          name: "linkedIn",
          imgSrc: require("./img/组13.png"),
        },
      ],
      imgSrc: require("./img/Frame.png"),
      // 密码输入框的输入类型
      passwordType: "password",
      // 记住登录状态
      checked: true,
      // 避免选中就会进入记住登录状态选项 存localStorage  checkedLS和checked区分开
      checkedLS: false,
      form: {
        // 手机前缀
        Area: "",
        // 手机号
        phone: "",
        // 用户名
        userName: "",
        // 密码
        password: "",
        // 验证码
        code: "",
      },
      // 存phone，带值
      phoneStorage: "",
      // 表单检验规则
      rules: {
        Area: [{ validator: validateUserArea, trigger: "blur" }], //, trigger: "blur"
        userName: [{ validator: validateUserName, trigger: "blur" }], //, trigger: "blur"
        password: [{ validator: validatePassword, trigger: "blur" }], //, trigger: "blur"
        phone: [{ validator: validatePhone, trigger: "blur" }], //, trigger: "blur"
        code: [{ validator: validateCode, trigger: "blur" }], //, trigger: "blur"
      },
      password: "",
      // lightning-main登录地址
      lightningUrl: "",
      // 返回信息
      returnInfo: "",
      // 登录方式：手机号、账号
      loginL: "account",
      // 号码前缀
      prefixOptions: [
        //   中国香港+852、中国澳门+853、中国台湾+886、美国+1、日本+81、德国+349、
        // 俄罗斯+7、英国+44、法国+33、意大利+39、西班牙+34、荷兰+31、波兰+48、巴西+55
        {
          value: "label.account.China.Mainland",
          label: "+86",
          country: "label.account.China.Mainland",
        },

        {
          value: "label.account.Macao",
          label: "+853",
          country: "label.account.Macao",
        },
        {
          value: "label.account.HongKong",
          label: "+852",
          country: "label.account.HongKong",
        },
        {
          value: "label.account.Taiwan",
          label: "+886",
          country: "label.account.Taiwan",
        },
        {
          value: "label.account.USA",
          label: "+1",
          country: "label.account.USA",
        },
        {
          value: "label.account.jp",
          label: "+81",
          country: "label.account.jp",
        },
        {
          value: "label.account.Germany",
          label: "+49",
          country: "label.account.Germany",
        },
        {
          value: "label.account.Russia",
          label: "+7",
          country: "label.account.Russia",
        },
        {
          value: "label.account.britain",
          label: "+44",
          country: "label.account.britain",
        },
        {
          value: "label.account.France",
          label: "+33",
          country: "label.account.France",
        },
        {
          value: "label.account.Italy",
          label: "+39",
          country: "label.account.Italy",
        },
        {
          value: "label.account.Spain",
          label: "+34",
          country: "label.account.Spain",
        },
        {
          value: "label.account.Netherlands",
          label: "+31",
          country: "label.account.Netherlands",
        },
        {
          value: "label.account.Poland",
          label: "+48",
          country: "label.account.Poland",
        },
        {
          value: "label.account.Brazil",
          label: "+55",
          country: "label.account.Brazil",
        },
      ],
      // 显示获取按钮
      isShowObtain: true,
      // 显示失效提示
      isShowInvalid: false,
      // 发送验证码倒计时
      count: 60,
      // 站点切换下拉框
      options1: [
        {
          value: "选项1",
          // label: "label.account.China.Station",
          label: "中国站",
          url: "accounts.cloudcc.cn/#/login",
        },
        {
          value: "选项2",
          // label: "label.account.International",
          label: "International",
          url: "accounts.cloudcc.com/#/login",
        },
      ],
      //站切换
      value: "",
      // 轮播图数据集合
      rotationList: [
        {
          imgSrc: require("./img/sale.svg"),
          product: "label.account.sale",
          title: "label.account.sale.introduce",
          detail:
            localStorage.getItem("REGION") === "cn"
              ? "https://www.cloudcc.cn/sale-cloud/salecloud.html"
              : "https://www.cloudcc.com/en/products/sales",
        },
        {
          imgSrc: require("./img/service6.svg"),
          product: "label.account.service",
          title: "label.account.service.introduce",
          detail:
            localStorage.getItem("REGION") === "cn"
              ? "https://www.cloudcc.cn/services-cloud/servicescloud.html"
              : "https://www.cloudcc.com/en/products/service",
        },
        {
          imgSrc: require("./img/market7.svg"),
          product: "label.account.market",
          title: "label.account.market.introduce",
          detail:
            localStorage.getItem("REGION") === "cn"
              ? "https://www.cloudcc.cn/market-cloud/marketcloud.html"
              : "https://www.cloudcc.com/en/products/marketing",
        },
      ],
      detailBtn:
        localStorage.getItem("REGION") === "cn"
          ? "https://www.cloudcc.cn/sale-cloud/salecloud.html"
          : "https://cloudcc.com/en/products/sales", // 轮播图查看详情按钮跳转链接,
      // 验证码获取按钮显示可用
      isAvailable: true,
      // 标题
      upgradeTitle: "",
      // 描述
      upgradeDescribe: "",
      // 当为true的时候，就是展示升级页面
      upgrade: false,
      // 倒计时是否完成
      CDcomplete: false,
      // 登陆链接
      href: "",
      // 为true就是 首次登录或者 有效期 到了
      passwordExpire: "",
      // 首次登陆
      firLogToChanPw: "",
      // 注册用户
      autoRegister: "",
      // 用户名
      username: "",
      // 修改密码方式
      modifyPwType: "",
      // 登录后修改密码接口参数
      gatewayUrl: "",
      // token
      accessToken: "",
      // 账号密码存本地 用于 记住密码
      parameter11: "",
      //是否显示其他登录按钮   // 目前还用于判断是否是奔驰环境
      isShowBtn: window.Glod.OTHER_LOGIN,
    };
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        this.phoneStorage = this.form.phone;
        this.returnInfo = "";
      },
    },
  },
  created() {
    localStorage.setItem("REGION", "cn");
    document.title = `Login | ${
      window.Glod.BASE_TITLE ? window.Glod.BASE_TITLE : "CloudCC"
    }`;
    // 获取是否记住密码
    this.checked = JSON.parse(localStorage.getItem("rememberPassword"));
    // 浏览器名称及版本
    this.explorer = obtain.getBrowser() + obtain.Browse();
    // 操作系统名称
    this.operatingSystem = obtain.getOperationSys();
    // 获取用户名
    this.form.userName = this.$route.params.userName || "";
    // 从url中获取多语言标识
    if (this.$route.query.language) {
      this.$i18n.locale = this.$route.query.language;
    }
    // 国内外源
    this.REGION = localStorage.getItem("REGION");
    if ("cn" === this.REGION) {
      this.value = "选项1";
      this.form.Area = "+86";
    } else {
      this.value = "选项2";
      this.form.Area = "+1";
    }
    // 记住登录状态登录
    this.checkedLS = localStorage.getItem("rememberPassword");
    // 记住登录状态改为  记住密码  回显账号/手机号、密码
    if (this.checkedLS === "true") {
      const parameter1 = localStorage.getItem("parameteL")
        ? JSON.parse(encry.de(localStorage.getItem("parameteL")))
        : "";
      if (parameter1.serviceName == "mobilePasswordLogin") {
        this.loginL = "phone";
        this.form.phone = parameter1.mobilePhone;
        this.form.password = parameter1.password;
      } else {
        this.loginL == "account";
        this.form.userName = parameter1.username || "";
        this.form.password = parameter1.pwd || "";
      }
    }
  },
  mounted() {
    // 隐藏加载框
    document.getElementById("lightning-loading").style.display = "none";
    // 通过企业微信登录，如果存在code那么获取企业微信的token
    if (localStorage.getItem("code")) {
      this.getToken();
    }
  },
  methods: {
    //其他登录按钮
    JumpURL() {
      window.open(window.Glod.OTHER_LOGIN, "_blank");
    },
    changeKey(type) {
      this.key = this.key++;
      if (type == "op") {
        this.isShowlogin = false;
      } else {
        this.isShowlogin = true;
      }
    },
    /**
     * 切换区号验证手机号
     */
    changeArea() {
      this.$refs.form.validateField("phone");
    },
    /**
     * 叉号按钮
     */
    delete1Btn(item) {
      this.form[item] = "";
      this.clearcheck(item);
    },
    /**
     * 切换站
     * @param {string} url 跳转链接
     */
    toLogin(url) {
      window.open("https://" + url);
      window.close();
    },
    /**
     * 对象转formdata格式 方法
     * @param  obj 对象数据
     * @returns  转为formdata格式的数据
     */
    jsToFormData(obj) {
      const formData = new FormData();
      Object.keys(obj).forEach((key) => {
        if (obj[key] instanceof Array) {
          obj[key].forEach((item) => {
            formData.append(key, item);
          });
          return;
        }
        formData.append(key, obj[key]);
      });
      return formData;
    },
    /**
     * 登录
     */
    logon() {
      // 防止多次点击重复请求
      if (this.loginFlag) {
        return;
      }
      this.loginFlag = true;
      // md5加密
      let parameter;
      const parameter11 = localStorage.getItem("parameteL")
        ? JSON.parse(encry.de(localStorage.getItem("parameteL")))
        : "";
      if (this.checkedLS === "true") {
        // 记住密码
        // 账号密码登录  可能会换号 密码回显是加密后的所以判断一下，如果改变用新密码重新加密 得重新存一下
        const parameter1 = {
          pwd:
            parameter11.pwd == this.form.password
              ? parameter11.pwd
              : this.form.password,
          username: this.form.userName,
          explorer: this.explorer,
          operatingSystem: this.operatingSystem,
          randomCode: "U8e3DsfH56cP",
        };
        if (this.checked) {
          localStorage.setItem(
            "parameteL",
            encry.en(JSON.stringify(parameter1))
          );
        } else {
          localStorage.removeItem("parameteL");
        }
        parameter = parameter1;
      } else {
        // 账号密码登录
        const parameter1 = {
          pwd: this.form.password,
          username: this.form.userName,
          explorer: this.explorer,
          operatingSystem: this.operatingSystem,
          randomCode: "U8e3DsfH56cP",
        };
        // 存 parameteL  用于 记住登录状态登录
        // 1、将对象转换为JSON格式字符串
        // JSON.stringify(object);
        // 2、将JSON字符串转换为对象
        // JSON.parse(jsonString);
        if (this.checked) {
          localStorage.setItem(
            "parameteL",
            encry.en(JSON.stringify(parameter1))
          );
        } else {
          localStorage.removeItem("parameteL");
        }
        parameter = parameter1;
      }

      // 重置密码之后会走这里
      if (this.parameter11) {
        parameter = this.parameter11;
      }
      // 存 checked 用于 记住登录状态登录
      localStorage.setItem("rememberPassword", this.checked);
      loginApi
        .logon(parameter)
        .then((res) => {
          if (res.data.result) {
            this.loginFlag = false;
            // todo status 状态。值为changePassword时意为修改密码；值为registerSuccess时意为注册成功；值为register时意为注册；值为mfavalidate意为MFA输入验证码；值为mfabound意为去绑定MFA
            // status状态。值为changePassword时意为修改密码；值为autoRegister时意为注册用户首次登录修改密码；值为registerSuccess时意为注册成功；值为register时意为注册；值为mfavalidate意为MFA输入验证码；值为mfabound意为去绑定MFA
            if (res.data.data.status) {
              // 有status时 分几种状态
              this.loginStatus = res.data.data.status;
              if (
                res.data.data.status == "changePassword" ||
                res.data.data.status == "register" ||
                res.data.data.status == "registerSuccess" ||
                res.data.data.status == "autoRegister"
              ) {
                // 修改密码 注册
                this.$router.replace({
                  path: "/changePassword",
                  query: {
                    username: this.form.userName,
                    accessToken: res.data.data.accessToken,
                    binding: res.data.data.binding,
                    modifyPwType: res.data.data.status,
                  },
                });
              } else if (res.data.data.status == "mfabound") {
                // 去绑定MFA
                this.$refs.enableMFA.open(res);
              } else {
                // mfavalidate 为MFA输入验证码
                this.$refs.validationMFA.open(res);
              }
            } else {
              //登录前清空用户信息
              localStorage.removeItem("userInformation");
              // 存token
              this.$CCDK.CCToken.setToken(res.data.data.accessToken);
              // 没有status时
              this.accessToken = res.data.data.accessToken;
              // 0、2不拼接，1拼接
              if (window.Glod.BINDING_ON_URL == "1") {
                this.href = "./#/?binding=" + res.data.data.accessToken;
              } else {
                this.href = "./#/";
              }
              window.location.replace(this.href);
              window.location.reload();
            }
          } else {
            if (res.data.returnCode === "ssouser" && res.data.data?.callUrl) {
              //前端需要根据返回状态判断是否属于SSO用户，其中result为false且returnCode为ssouser时属于SSO用户。
              //如果属于SSO用户则根据返回参数请求重定向地址  请求地址即返回参数中的callUrl
              window.location.replace(res.data.data.callUrl);
            } else {
              if (res.data.returnCode === "ssouser") {
                //前端需要根据返回状态判断是否属于SSO用户，其中result为false且returnCode为ssouser时属于SSO用户。
                //如果属于SSO用户则根据返回参数请求重定向地址  请求地址即返回参数中的callUrl
                window.location.replace(res.data.data.callUrl);
              } else {
                this.loginFlag = false;
                this.returnInfo = res.data.returnInfo;
                // 因为验证需要接口数据 再验证一次
                this.submitForm();
              }
            }
            // 4. 登录失败，如果包含tryProducts集合字段，为试用产品名称
            // 5. 试用组织登录失败返回示例说明
            // - applyStatus，1：可进行延长试用申请，2：不能申请试用 只能订购；3：终止试用
            // - applyToken，申请延长试用时需要用到的token，只能使用一次
            if (res.data.data && res.data.data.tryProducts) {
              // 如果包含tryProducts集合字段，为试用产品名称
              if (
                res.data.data.applyStatus != 3 &&
                res.data.data.applyStatus != null
              ) {
                // 未终止试用才跳
                const storeToken = res.data.data.storeToken;
                const applyToken = res.data.data.applyToken;
                const applyStatus = res.data.data.applyStatus;
                window.location.replace(
                  `${window.Glod.APP_STORE_URL}/#/home/expirationReminder?applyToken=` +
                    applyToken +
                    "&storeToken=" +
                    storeToken +
                    "&applyStatus=" +
                    applyStatus
                );
              }
            }
          }
        })
        .catch(() => {
          this.loginFlag = false;
        });
    },
    /**
     * 重置密码后登陆 后端说不好返就只能带参到登陆页调登陆接口
     */
    resetLogin(data) {
      this.checkedLS === "false";
      this.parameter11 = data;
      if (this.checked) {
        localStorage.setItem("parameteL", encry.en(JSON.stringify(data)));
      } else {
        localStorage.removeItem("parameteL");
      }
      this.logon();
    },
    /**
     * 使用短信验证码登录
     */
    sendMessageForLogin() {
      const parameter = {
        mobile: this.form.phone,
        lang: this.$i18n.locale,
        countryCode: this.form.Area,
      };
      loginApi
        .sendMessageForLogin(parameter)
        .then(() => {})
        .catch(() => {});
    },

    /**
     * 跳转注册页
     */
    toRegister() {
      if (this.REGION === "cn") {
        // 国内 https://accounts.cloudcc.cn/    https://www.cloudcc.cn/lianxiwomen.html
        window.open("https://accounts.cloudcc.cn/registered");
      } else {
        // 国外
        let routeUrl = this.$router.resolve({
          path: "/register",
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    /**
     * 验证账号 手机号
     */
    verifyAccount() {
      // this.$refs.validationMFA.open();
      // this.$refs.enableMFA.open();
      let isValid = this.submitForm();
      // 校验通过
      if (isValid) {
        // 调登录接口
        this.logon();
      }
    },
    /**
     * 提交登录表单
     */
    submitForm() {
      // 是否校验通过标识
      let isChecked;
      this.$refs.form.validate((valid) => {
        if (valid) {
          isChecked = true;
        } else {
          isChecked = false;
        }
      });
      return isChecked;
    },
    /**
     * 清除某项验证
     */
    clearcheck(item) {
      if (this.$refs.form) {
        this.$refs.form.clearValidate(item);
      }
    },
    /**
     * 清除验证
     */
    resetFields() {
      this.$refs.form.resetFields();
    },
    /**
     * 企业微信扫码登录
     */
    qiyeLogin() {
      this.$axios
        .post(window.Glod.ccexIntegration + "/api/epwechat/weburl", {
          redirecturi: "https%3A%2F%2Faccounts.cloudcc.cn%2F%23%2Femptypage",
        })
        .then((res) => {
          if (res.data.result == true) {
            window.open(res.data.data.oauthurl);
          }
        })
        .catch(() => {});
    },
    /**
     * 通过企业微信登录
     */
    getToken() {
      const code = localStorage.getItem("code");
      this.$axios
        .get(
          window.Glod.ccexIntegration + "/api/epwechat/weblogin?code=" + code
        )
        .then((res) => {
          if (res.data.returnCode == 10000) {
            const lightningUrl = res.data.data.lightingUrl;
            const binding = res.data.data.token;
            // 跳转
            window.location.href =
              lightningUrl +
              "/#/homePageObject/standardPage?binding=" +
              binding;
            localStorage.removeItem("code");
          } else if (res.data.returnCode == 10001) {
            this.$message.warning(this.$t("label.account.No.account.binding"));
            localStorage.removeItem("code");
          }
        })
        .catch(() => {});
    },
    /**
     * 显示密码
     */
    displayPW() {
      this.imgSrc =
        this.imgSrc == require("./img/Frame.png")
          ? require("./img/可见.png")
          : require("./img/Frame.png");
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
    /**
     * 获取按钮、倒计时
     */
    obtain() {
      // 获取按钮禁用不走发送验证码按钮
      if (this.isAvailable) {
        return;
      }
      // 获取按钮可以点击
      if (!this.isAvailable) {
        this.isShowObtain = false;
        this.timer = setInterval(() => {
          this.count--;
          if (this.count == -1) {
            clearInterval(this.timer);
            this.isShowObtain = true;
            this.count = 60;
          }
        }, 1000);
      }
      this.sendMessageForLogin();
    },
    /**
     * 修改密码弹框隐藏
     */
    recovery() {
      this.dialogFormVisible = false;
    },
    /**
     * 轮播图切换更换按钮跳转链接
     * @param {Number} index 当前索引
     */
    change1(index) {
      this.detailBtn = this.rotationList[index].detail;
    },
    /**
     * 轮播图按钮跳转
     */
    viewDetails() {
      window.open(this.detailBtn);
    },
    /**
     * 改变登录方式
     */
    changeLoginL(type) {
      this.resetFields();
      this.returnInfo = "";
      // 清空数据
      if (type == "account") {
        this.form.phone = "";
        this.form.userName = "";
        this.form.password = "";
        this.form.code = "";
      } else if (type == "phone") {
        this.form.phone = this.phoneStorage;
        this.form.userName = "";
        this.form.password = "";
        this.form.code = "";
      } else if (type == "Verification") {
        this.form.phone = this.phoneStorage;
        this.form.userName = "";
        this.form.password = "";
        this.form.code = "";
        if (this.form.phone.length > 0) {
          this.isAvailable = false;
        }
      }
      this.loginL = type;
    },
    // 服务条款、隐私政策
    Jump(path) {
      window.open(path);
    },
  },
};
</script>

<style lang="scss" scoped>
/*button动画效果*/
$button-width: 120px;
$button-height: 60px;
$spinner-width: $button-width / 6;
// $btn-bg: #2c3248;
$btn-bg: #006dcc;
$text-light: #fff;
$font-size: 24px;
$margin: 40px 0 16px 0;
.button-loading {
  height: 44px;
  margin: 30px 0 10px 0 !important;
  font-size: 16px !important;
}
@import "style/loadbutton";
::v-deep .el-input__prefix {
  left: 8px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #006dcc;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #006dcc;
  border-color: #006dcc;
}
::v-deep .el-button:focus,
.el-button:hover {
  background-color: #fff;
}
::v-deep .el-select-dropdown__item.selected {
  color: #006dcc;
  font-weight: 700;
}
::v-deep .el-form-item__error {
  /* 输入框验证 */
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  top: -12%;
  left: 15px;
  background: #fff;
  padding: 0px 7px;
  color: #f11b21;
}
.codeD {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    margin-top: 10px;
  }
}
.login {
  min-height: 100vh;
  width: 100%;
  background: #f6f6f6 url(./img/Slice2x.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .contentAll {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .trial {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      position: fixed;
      top: 25px;
      left: -80px;
      .onTrial {
        display: flex;
        .onTrial2 {
          display: flex;
          align-items: center;
          margin-right: 40px;
          ::v-deep .el-input__inner {
            border: none;
            height: 30px;
            line-height: 30px;
            padding-left: 5px;
          }
          ::v-deep .el-input__icon {
            line-height: 20px;
          }
          img {
            width: 25px;
            margin: 0 10px 0 15px;
          }
          .icon1 {
            margin-left: 30px;
          }
          .select80 {
            width: 100px;
          }
          .select130 {
            width: 130px;
          }
        }
      }
    }
    .content {
      // min-height: 100%;
      width: 1127px;
      height: 648px;
      display: flex;
      justify-content: center;
      background: #ffffff;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12);
      border-radius: 16px 16px 16px 16px;
      opacity: 1;

      .content_right {
        width: 699px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        .loginForm {
          width: 424px;
          /* height: 470px; */
          background: #ffffff;
          /* box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3); */
          border-radius: 3px;
          /* padding: 30px 25px; */
          display: flex;
          /* justify-content: center; */
          /* align-items: center; */
          flex-direction: column;
          ::v-deep .el-select-dropdown__list {
            height: 210px;
          }
          .el-input {
            /* 输入框 */
            font-size: 14px;
            // background-color:transparent;  //设置input输入框的背景颜色为透明色
          }
          ::v-deep .el-select > .el-input {
            font-size: 14px;
          }
          .el-checkbox {
            font-size: 14px;
            color: #006dcc;
            font-weight: 400;
          }
          .el-input__icon1 {
            line-height: 20px;
            height: 14px;
            width: 14px;
            position: absolute;
            top: 15px;
            left: 0px;
          }
          ::v-deep .el-checkbox__inner {
            /* 记住密码单选框 */
            // width: 18px;
            // height: 18px;
          }
          ::v-deep .el-checkbox__label {
            /* 记住密码文字部分 */
            // font-size: 20px;
          }

          ::v-deep .el-input__inner {
            /* 输入框 */
            height: 44px;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            border: 1px solid #e1e1e1;
          }
          ::v-deep .el-form-item.is-error .el-input__inner {
            border: 1px solid #f11b21;
          }
          ::v-deep .el-form-item__content {
            line-height: 30px;
            position: relative;
            font-size: 14px;
          }
          .topDiv {
            /* padding: 0px 90px 0 20px; */
            width: 424px;
            /* height: 90px; */
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 10px 0 40px 0;
            img {
              width: 197px;
            }
          }
          & > span {
            font-size: 30px;
            line-height: 40px;
            font-weight: bold;
          }

          .formDiv {
            width: 100%;
            min-height: 280px;
            opacity: 1;
            border: 1px solid #ffffff;
            .formLogin {
              ::v-deep .el-checkbox__inner::after {
                // height: 10px;
                left: 4px;
                top: 0;
              }
              .formItem {
                position: relative;
                .delete1Btn {
                  width: 15px;
                  position: absolute;
                  top: 15px;
                  right: 8px;
                  cursor: pointer;
                }
                .option1 {
                  display: flex;
                  width: 300px;
                  justify-content: space-between;
                  font-size: 14px;
                  .span50 {
                    display: inline-block;
                    width: 50px;
                  }
                }
                .select112 {
                  width: 112px;
                  margin-right: 10px;
                }
              }
            }
            .tick {
              width: 20px;
              position: absolute;
              top: 9px;
              right: 12px;
            }
            .countDown {
              position: absolute;
              top: 8px;
              right: 10px;
              color: #006dcc;
              font-size: 14px;
              cursor: pointer;
            }
            .Available {
              color: #999;
              cursor: not-allowed;
            }
            .invalid {
              background: #7b7c7f;
              width: 218px;
              text-align: center;
              height: 50px;
              line-height: 50px;
              position: absolute;
              top: 5px;
              right: 130px;
              color: #fff;
              border-radius: 2px;
              font-size: 24px;
            }
            .agreeDiv {
              display: flex;
              justify-content: center;
              .agree {
                font-size: 15px;
                color: #000000;
                line-height: 20px;
                // margin: 22px 0 30px 0;
                color: #999999;
                letter-spacing: 0.3px;
                font-weight: 400;
                span {
                  /* color: #4588e8; */
                  /* font-size: 18px; */
                  font-weight: 400;
                  line-height: 14px;
                  cursor: pointer;
                  color: #333333;
                  letter-spacing: 0.3px;
                  font-weight: 400;
                }
              }
            }
            .loginBtn {
              width: 100%;
              /*height: 60px;*/
              background: #2c3248;
              border-radius: 3px;
              border: none;
              color: #fff;
              font-size: 24px;
              line-height: normal;
              margin: 40px 0 16px 0;
            }
            .forgetPass {
              font-size: 14px;
              font-weight: 400;
              color: #006dcc;
              line-height: 16px;
              display: flex;
              justify-content: space-between;
              cursor: pointer;
              align-items: center;
            }
            .checkedOrTo {
              display: flex;
              justify-content: center;
              font-size: 18px;
              .loginList {
                line-height: 18px;
                display: flex;
                .loginList1 {
                  span {
                    font-size: 14px;
                    color: #006dcc;
                    margin-right: 10px;
                    cursor: pointer;
                    display: inline-block;
                    font-weight: 400;
                    /* min-width: 50%; */
                  }
                  i {
                    display: inline-block;
                    height: 12px;
                    border: 0.5px solid #e1e1e1;
                    margin-right: 10px;
                  }
                }

                .Loginby {
                  margin-right: 10px;
                }
              }

              & > a {
                font-weight: 400;
                color: #006dcc;
                cursor: pointer;
              }
            }
            .otherLogin {
              min-height: 70px;
              cursor: pointer;
              text-align: center;
              margin-top: 40px;
              .el-divider__text {
                font-size: 12px;
                color: #bdbdbd;
              }
              span {
                display: inline-block;
                width: 100%;
                font-size: 18px;
                color: #333333;
              }
              .icon {
                width: 34px;
                height: 34px;
              }
              .otherLoginList {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                align-items: center;
                margin-bottom: 20px;
                .el-button {
                  display: block;
                  padding: 12px 16px;
                }
              }
            }
          }
        }
        .footer2 {
          color: #999999;
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
      .content_left {
        width: 428px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid rgba(230, 230, 230, 1);
        .el-carousel__container {
          /* 轮播高度 */
          position: relative;
          height: 700px;
        }
        ::v-deep .el-carousel__container {
          height: 500px;
          // height: calc(26vw);
        }
        ::v-deep .el-carousel__indicator {
          width: 15px;
          height: 6px;
        }
        ::v-deep .el-carousel__button {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #2c3248;
        }
        .el-carousel__item h3 {
          color: #475669;
          font-size: 18px;
          line-height: 300px;
          margin: 0;
        }
        img {
          display: flex;
          margin: 0 auto;
          width: 100%;
        }
        .introduce {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #333333;
          span {
            font-size: 18px;
          }
          p {
            font-size: 14px;
            margin: 10px 0;
          }
        }
        .rotationImg {
          height: 400px;
          padding-top: 20px;
          img {
            width: 400px;
          }
        }
        /* 走马灯 */
        .detail {
          min-width: 88px;
          padding: 0 10px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          color: rgba(51, 51, 51, 1);
          border: 1px solid rgba(51, 51, 51, 1);
          border-radius: 4px;
          cursor: pointer;
          margin: 15px auto;
          font-size: 14px;
        }
      }
      .mb24 {
        margin-bottom: 24px;
      }
      .mb20 {
        margin-bottom: 20px;
      }
    }
    .contentBottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
      .footer {
        ::v-deep .el-button {
          border: none;
        }
        div {
          width: 100%;
          // margin: 35px 0 53px 0;
          display: flex;
        }
        span {
          color: #999999;
          font-size: 14px;
        }
        img {
          width: 87px;
        }
      }
      .onTrial1 {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
          color: #333333;
          font-weight: 400;
        }
        .onTrial1_button {
          background: #fff;
          border: 1px solid #006dcc;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #006dcc;
          cursor: pointer;
          margin-left: 10px;
          padding: 8px 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .login {
    background: #fff;
    .contentAll {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .trial {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        position: fixed;
        top: 30px;
        left: 0px;
        z-index: 99;
        .onTrial {
          display: flex;
          margin-right: 5%;

          .onTrial2 {
            display: flex;
            align-items: center;
            margin-right: 0;

            ::v-deep .el-input__inner {
              border: none;
              height: 30px;
              line-height: 30px;
              padding-left: 5px;
            }
            ::v-deep .el-input__icon {
              line-height: 20px;
            }
            img {
              width: 25px;
              margin: 0 10px 0 15px;
            }
            .icon1 {
              margin-left: 30px;
            }
            .select80 {
              width: 100px;
            }
            .select130 {
              width: 130px;
            }
          }
        }
      }
      .content {
        width: 90%;
        box-shadow: none;
        // margin-top: 50px;
        .content_left {
          /* 隐藏轮播 */
          display: none !important;
        }
        .content_right {
          // width: 100% !important;

          .loginForm {
            width: 95% !important;
            .topDiv {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              margin: 40px 0 40px 0 !important;
            }
            // .agree {
            //   margin: 60px 0 0 0 !important;
            // }
            .formDiv {
              .loginBtn {
                margin: 30px 0 30px 0 !important;
              }
            }
            .onTrial1 {
              margin-bottom: 20px;
            }
          }
          .trial {
            width: 100%;
            display: flex;
            justify-content: space-between;
            position: static;
            right: 0px;
            top: 0px;
            margin-right: 10px;
            margin-top: 20px;
            .onTrial {
              display: flex;
              .onTrial2 {
                margin-right: 0;
              }
            }
          }
          .otherLogin {
            margin-top: 100px !important;
          }
        }
      }
      .contentBottom {
        display: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        .footer {
          ::v-deep .el-button {
            border: none;
          }
          div {
            width: 100%;
            // margin: 35px 0 53px 0;
            display: flex;
          }
          span {
            color: #999999;
            font-size: 14px;
          }
          img {
            width: 87px;
          }
        }
        .onTrial1 {
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 14px;
            color: #333333;
            font-weight: 400;
          }
          .onTrial1_button {
            background: #fff;
            border: 1px solid #006dcc;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            color: #006dcc;
            cursor: pointer;
            margin-left: 10px;
            padding: 8px 20px;
          }
        }
      }
    }
  }
}
</style>
